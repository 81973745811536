<template>
    <a-modal
        ref="createModal"
        :title="readOnly ? '详情' : form.universityId ? '学校编辑' : '新增操作'"
        :width="640"
        :visible="visible"
        @cancel="close"
        @ok="confirm"
        :confirmLoading="confirmLoading"
        :okButtonProps="{
            props: {
                disabled: readOnly?true:false,
            },
        }"
    >
        <a-spin :spinning="loading">
            <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-row>
                    <a-col :span="24">
                        <template>
                            <a-upload :disabled="readOnly?true:false"
                                list-type="picture-card"
                                class="avatar-uploader"
                                :show-upload-list="false"
                                :headers="{
                                    Authorization: token,
                                }"
                                :action="config.apiDomain + '/api/file/upload'"
                                :before-upload="beforeUpload"
                                @change="handleChange"
                            >
                                <img v-if="form.logo" :src="config.staticResourceDomain+form.logo" alt="avatar" class="avatar" />
                                <div v-else>
                                    <a-icon :type="loading ? 'loading' : 'plus'" />
                                    <div class="ant-upload-text">上传LOGO图片</div>
                                </div>
                            </a-upload>
                        </template>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="名称" prop="universityName">
                            <a-input v-model="form.universityName" placeholder="请输入学校名称" :disabled="readOnly?true:false" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="联系人" prop="linkman">
                            <a-input v-model="form.linkman" placeholder="请输入联系人姓名" :disabled="readOnly?true:false" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="联系电话" prop="linkmanPhone">
                            <a-input v-model="form.linkmanPhone" placeholder="请输入联系电话" :disabled="readOnly?true:false" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="邮箱" prop="email">
                            <a-input v-model="form.email" placeholder="请输入邮箱" :disabled="readOnly?true:false" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="地址" prop="address">
                            <a-input v-model="form.address" placeholder="请输入地址" :disabled="readOnly?true:false" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="邮编" prop="postcode">
                            <a-input v-model="form.postcode" placeholder="请输入邮政编码" :disabled="readOnly?true:false" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item label="院长用户id" prop="postcode">
                            <a-input v-model="form.masterUserId" placeholder="请输入邮政编码" :disabled="readOnly?true:false" />
                        </a-form-model-item>
                    </a-col>
                </a-row>
            </a-form-model>
        </a-spin>
    </a-modal>
</template>

<script>
import { listUniversity, addUniversity, updateUniversity } from '@/api/rongyi/organization/university'
import rryConfig from '@/../config/rongyiYun.config'
// 表单字段
export default {
    data() {
        return {
            config: rryConfig,
            logoUrl: '',
            token: '',
            temp: {},
            confirmLoading: false,
            readOnly: false,
            visible: false,
            loading: false,
            // form: this.$form.createForm(this),
            // value: undefined,
            labelCol: {
                xs: { span: 12 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 },
            },
            // 表单属性:
            form: {},
            // 表单校验
            rules: {
                universityName: [{ required: true, message: '名称不能为空', trigger: 'blur' }],
                email: [
                    {
                        required: true,
                        type: 'email',
                        message: "请输入正确的邮箱地址",
                        trigger: ['blur', 'change'],
                    },
                ],
                linkmanPhone: [
                    {
                        required: true, 
                        pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
                        message: '请输入正确的手机号码',
                        trigger: 'blur',
                    },
                ],
                postcode: [
                    {
                        required: true, 
                        pattern: /[1-9][0-9]{5}/,
                        message: '请输入正确的邮编',
                        trigger: 'blur',
                    },
                ],
                address: [{ required: true, message: '地址不能为空', trigger: 'blur' }],
                linkman: [{ required: true, message: '联系人不能为空', trigger: 'blur' }],
            },
        }
    },
    created() {
        this.getDicts('sys_normal_disable').then((response) => {
            this.statusOptions = response.data
        })
        listUniversity().then((response) => {
            // this.deptOptions = this.handleTree(response.data, 'deptId')
            const dept = {}
            // this.deptOptions.push(dept)
        })
    },
    methods: {
        // 由于要用传进来的值做判断,将显示和隐藏放在内部做处理
        show(data, readOnly) {
            if (data) {
                // 修改行为
                this.form = Object.assign({}, data) || {}
            } else {
                // 新增行为
                this.reset()
            }
            this.readOnly = typeof readOnly !== 'undefined' ? readOnly === true : false
            console.log("this.readOnly");
            console.log(this.readOnly);
            this.visible = true
        },
        // 关闭模态框
        close() {
            this.visible = false
            this.reset()
        },
        confirm() {
            console.log('form')
            console.log(this.form)
            this.confirmLoading = true
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    console.log('valid success')
                    console.log(this.form)
                    // 进行新增行为:
                    if (
                        this.form.universityId != '' &&
                        this.form.universityId != null &&
                        this.form.universityId != undefined
                    ) {
                        console.log('universityId is not null')
                        console.log(this.form.universityId)
                        // 刷新表格
                        updateUniversity(this.form).then((response) => {
                            if (response.code === 200) {
                                this.$message.success('修改成功')
                                // 关闭本组件
                                this.visible = false
                                // 调用外部刷新列表方法
                                this.$emit('handle-success')
                                // 刷新表单
                                this.reset()
                                this.confirmLoading = false
                            } else {
                                this.$message.error(response.msg)
                                this.confirmLoading = false
                            }
                        })
                    } else {
                        console.log('form2')
                        console.log(this.form)
                        // 新增
                        addUniversity(this.form).then((response) => {
                            console.log('this.form')
                            console.log(this.form)
                            if (response.code === 200) {
                                this.$message.success('新增成功')
                                // 关闭本组件
                                this.visible = false
                                // 调用外部刷新列表方法
                                this.$emit('handle-success')
                                // 刷新表单
                                this.reset()
                                this.confirmLoading = false
                            } else {
                                this.$message.error(response.msg)
                                this.confirmLoading = false
                            }
                        })
                    }
                } else {
                    console.log('valid false')
                    console.log(this.form)
                    return (this.confirmLoading = false)
                }
            })
        },
        // 表单重置
        reset() {
            this.form = {}
        },
        filterStrArrary(list) {
            for (let index = 0; index < list.length; index++) {
                if (list[index].children === '') {
                    list[index].children = []
                }
                if (list[index].children) {
                    this.filterStrArrary(list[index].children)
                }
            }
        },
        //操作logo上传
        handleChange(info) {
            const self = this
            if (info.file.status === 'uploading') {
                this.loading = true
                return
            }
            if (info.file.status === 'done') {
                console.log(info.file.response.data.name)
                this.form.logo = info.file.response.data.url
                this.loading = false
            }
        },
        beforeUpload(file) {
            console.log('beforeUpload')
            //获取token
            const tk = localStorage.getItem('token')
            if (tk) {
                this.token = 'Bearer ' + tk
            }

            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
            if (!isJpgOrPng) {
                this.$message.error('只能上传jpeg、png格式的图片文件!')
            }
            const isLt2M = file.size / 1024 / 1024 < 2
            if (!isLt2M) {
                this.$message.error('图像大小不能超过 2MB!')
            }
            return isJpgOrPng && isLt2M
        },
    },
}
</script>
<style scoped>
.avatar {
    width: 128px;
    height: 128px;
}
.avatar-uploader {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.avatar-uploader > .ant-upload {
    width: 128px;
    height: 128px;
}
.ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
}
</style>